import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Frame } from '../frame/frame'
import { ReadMore } from '../read-more-button/read-more-button'

import { replaceMarkdown } from '../../utils/replaceMarkdown'
import { extractFirstParagraph } from '../../utils/extractFirstParagraph'

export const About = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="about"
        title="About Us"
        description={
          <>
            {data.graphCMSData.sectionHeadings
              .filter(heading => heading.type === 'AboutUs')
              .map(heading => (
                <p
                  dangerouslySetInnerHTML={{
                    __html: extractFirstParagraph(
                      replaceMarkdown(heading.text)
                    ),
                  }}
                />
              ))}

            <ReadMore url="/about" />
          </>
        }
      />
    )}
  />
)
