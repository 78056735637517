import React from 'react'
import styles from './customers.module.scss'
import { Frame } from '../frame/frame'
import { Customer } from '../customer/customer'
import { replaceMarkdown } from '../../utils/replaceMarkdown'

import { StaticQuery, graphql } from 'gatsby'

export const Customers = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          customers(where: { status: PUBLISHED }) {
            id
            name
            description
            website
            logo {
              url
            }
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="customers"
        title="Customer Stories"
        description={data.graphCMSData.sectionHeadings
          .filter(heading => heading.type === 'Customers')
          .map(heading => (
            <p
              dangerouslySetInnerHTML={{
                __html: replaceMarkdown(heading.text),
              }}
            />
          ))}
      >
        <ul className={styles.customers}>
          {data.graphCMSData.customers.map(customer => (
            <Customer
              id={`customer--${customer.id}`}
              customer={customer}
              key={customer.id}
            />
          ))}
        </ul>
      </Frame>
    )}
  />
)
