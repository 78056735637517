import React from 'react'
import { Frame } from '../frame/frame'

import styles from './initiatives.module.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Initiative } from '../initiative/initiative'
import { replaceMarkdown } from '../../utils/replaceMarkdown'

export const Initiatives = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          initiatives(orderBy: order_ASC, where: { status: PUBLISHED }) {
            name
            description
            website
            logo {
              url
            }
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="initiatives"
        title="Initiatives and Projects"
        description={data.graphCMSData.sectionHeadings
          .filter(heading => heading.type === 'Initiatives')
          .map(heading => (
            <p
              dangerouslySetInnerHTML={{
                __html: replaceMarkdown(heading.text),
              }}
            />
          ))}
      >
        <ul className={styles.initiatives}>
          {data.graphCMSData.initiatives.map(initiative => (
            <Initiative
              id={`initiative--${initiative.id}`}
              initiative={initiative}
              key={initiative.id}
            />
          ))}
        </ul>
      </Frame>
    )}
  />
)
