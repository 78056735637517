import React, { Component } from 'react'

import { About } from '../about/about'
import { TeamIntro } from '../team-intro/team-intro'
import { Footer } from '../footer/footer'
import { Intro } from '../intro/intro'
import { ServicesIntro } from '../services-intro/services-intro'
import { Initiatives } from '../initiatives/initiatives'
import { Customers } from '../customers/customers'

export class Home extends Component {
  render() {
    return (
      <div id="top">
        <Intro />
        <About />
        <ServicesIntro />
        <TeamIntro />
        <Customers />
        <Initiatives />
        <Footer />
      </div>
    )
  }
}
