import React from 'react'

import styles from './customer.module.scss'

import { replaceMarkdown } from '../../utils/replaceMarkdown'

export const Customer = ({ customer, id }) => (
  <li className={styles.customer} id={id}>
    <img
      src={customer.logo.url}
      alt={customer.name}
      className={styles.customerLogo}
    />
    <div className={styles.customerDescription}>
      {!!customer.website && (
        <a
          href={customer.website}
          target="_blank"
          className={styles.headerLink}
          rel="noopener noreferrer"
        >
          <h3 className={styles.header}>{customer.name}</h3>
        </a>
      )}
      {!customer.website && <h3 className={styles.header}>{customer.name}</h3>}
      <p
        dangerouslySetInnerHTML={{
          __html: replaceMarkdown(customer.description),
        }}
      />
    </div>
  </li>
)
