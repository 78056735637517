import React from 'react'

import { Layout } from '../components/layout/layout'
import { Home } from '../components/home/home'

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage
