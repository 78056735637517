import React from 'react'

import styles from './initiative.module.scss'

import { replaceMarkdown } from '../../utils/replaceMarkdown'

export const Initiative = ({ initiative, id }) => (
  <li className={styles.initiative} id={id}>
    <img
      src={initiative.logo.url}
      alt={initiative.name}
      className={styles.initiativeLogo}
    />
    <div className={styles.initiativeDescription}>
      {!!initiative.website && (
        <a
          href={initiative.website}
          target="_blank"
          className={styles.headerLink}
          rel="noopener noreferrer"
        >
          <h3 className={styles.header}>{initiative.name}</h3>
        </a>
      )}
      {!initiative.website && (
        <h3 className={styles.header}>{initiative.name}</h3>
      )}
      <p
        dangerouslySetInnerHTML={{
          __html: replaceMarkdown(initiative.description),
        }}
      />
    </div>
  </li>
)
