import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { replaceMarkdown } from '../../utils/replaceMarkdown'
import { extractFirstParagraph } from '../../utils/extractFirstParagraph'

import { Frame } from '../frame/frame'
import { ReadMore } from '../read-more-button/read-more-button'
import styles from './team-intro.scss'

export const TeamIntro = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          teamMembers(where: { status: PUBLISHED }) {
            id
            fullName
            title
            description
            photo {
              url
            }
            socialMedia {
              type
              link
            }
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="team"
        bgClassName={styles.bg}
        title="Our Team"
        description={
          <>
            {data.graphCMSData.sectionHeadings
              .filter(heading => heading.type === 'Team')
              .map(heading => (
                <p
                  dangerouslySetInnerHTML={{
                    __html: extractFirstParagraph(
                      replaceMarkdown(heading.text)
                    ),
                  }}
                />
              ))}
            <ReadMore url="/team" />
          </>
        }
      />
    )}
  />
)
