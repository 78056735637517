import React from 'react'

import { Frame } from '../frame/frame'
import { ReadMore } from '../read-more-button/read-more-button'

import { replaceMarkdown } from '../../utils/replaceMarkdown'
import { extractFirstParagraph } from '../../utils/extractFirstParagraph'

import { StaticQuery, graphql } from 'gatsby'

export const ServicesIntro = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          services(orderBy: order_ASC, where: { status: PUBLISHED }) {
            name
            order
            description
            icon
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="services"
        title="Services"
        description={data.graphCMSData.sectionHeadings
          .filter(heading => heading.type === 'Services')
          .map(heading => (
            <p
              dangerouslySetInnerHTML={{
                __html: extractFirstParagraph(replaceMarkdown(heading.text)),
              }}
            />
          ))}
      >
        <ReadMore url="/services" />
      </Frame>
    )}
  />
)
