import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './intro.module.scss'
import logo from '../../images/favicon2.svg'
import { Decorator } from '../decorator/decorator'
import { MainMenu } from '../main-menu/main-menu'
import { Wrapper } from '../wrapper/wrapper'

export const Intro = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <div className={styles.outerWrapper}>
        <Wrapper>
          <div className={styles.innerWrapper}>
            <div className={styles.contentTop}>
              <MainMenu type="primary" />
              <img src={logo} alt="" className={styles.logo} />
              <h1 className={styles.welcome}>
                Hi, we are Cloud&nbsp;Corridor.
              </h1>
              <Decorator />
              {data.graphCMSData.sectionHeadings
                .filter(heading => heading.type === 'Heading')
                .map(heading => (
                  <p className={styles.pitch} key={heading.type}>
                    {heading.text}
                  </p>
                ))}
            </div>

            <div className={styles.contentBottom}>
              <a href="#about" className={styles.container}>
                <div className={styles.chevron} />
                <div className={styles.chevron} />
                <div className={styles.chevron} />
              </a>
            </div>
          </div>
        </Wrapper>
      </div>
    )}
  />
)
